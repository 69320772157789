









import { IDetailData } from '@/api/order';
import { ProductTypeEnum } from '@/api/type/base';
import { AddressItem } from '@/api/user/address';
import { Vue, Component, InjectReactive } from 'vue-property-decorator';
@Component({
  name: ''
})
export default class extends Vue {
  @InjectReactive()
  orderDetail!: IDetailData;

  get productTypeEnum() {
    return ProductTypeEnum;
  }

  get addressInfo(): AddressItem {
    return JSON.parse(this.orderDetail.receiveAddress);
  }
}



















import { expressBaseData, getExpressRecordList, IDetailData } from '@/api/order';
import { Vue, Component, InjectReactive } from 'vue-property-decorator';
import getOrderState, { BaseOrderStateFnKey } from '../../commFunction/getOrderState';
@Component({
  name: ''
})
export default class extends Vue {
  current = 0;
  list: expressBaseData[] = [];
  @InjectReactive()
  orderDetail!: IDetailData;

  get baseOrderStateFnKey() {
    return BaseOrderStateFnKey;
  }

  get orderState() {
    return getOrderState(this.orderDetail);
  }

  onChange(index: number) {
    this.current = index;
  }

  handleClickItem() {
    this.$router.push({
      name: 'OrderExpress',
      params: { orderCode: this.orderDetail.orderCode },
      query: { orderImg: this.orderDetail.skuImgUrl }
    });
  }
  async getExpressRecord() {
    const { payload } = await getExpressRecordList(this.orderDetail.orderCode);
    this.list = payload;
  }

  created() {
    this.getExpressRecord();
  }
}

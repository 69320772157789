











































import { IDetailData } from '@/api/order';
import { ProductTypeEnum } from '@/api/type/base';
import dayjs from 'dayjs';

import { Vue, Component, InjectReactive, Inject } from 'vue-property-decorator';
import getOrderState, { BaseOrderStateFnKey } from '../../commFunction/getOrderState';
@Component({
  name: ''
})
export default class extends Vue {
  @InjectReactive()
  orderDetail!: IDetailData;

  @Inject({ from: 'reload' })
  reload!: () => void;

  get expressDownTime() {
    const expireEndTime = dayjs(this.orderDetail.expressTime).add(15, 'day');
    const now = dayjs();
    return expireEndTime.diff(now, 'ms');
  }

  get spuType() {
    return this.orderDetail.spuType;
  }

  get baseOrderStateFnKey() {
    return BaseOrderStateFnKey;
  }

  get orderState() {
    return getOrderState(this.orderDetail);
  }

  get productTypeEnum() {
    return ProductTypeEnum;
  }

  get countDownTime() {
    const countDownTime = dayjs(this.orderDetail.expireTime);
    const now = dayjs();
    return countDownTime.diff(now, 'ms');
  }
}

































































import { Component } from 'vue-property-decorator';
import BaseOrderDetail from '@/views/order/order-detail/index.vue';
@Component({
  name: '',
  extends: BaseOrderDetail
})
export default class extends BaseOrderDetail {}
